const _version = "2.4.1 (7033b76e48c111bdd88e0bf4da2337cf01755c46)"

function version() {
    return _version;
}

export default version








